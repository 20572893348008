import { captureException } from '@sentry/nextjs';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

const Custom404 = () => {
  const router = useRouter();

  captureException(new Error('404 error'));

  useEffect(() => {
    router.replace('/');
  });

  return null;
};

export default Custom404;
